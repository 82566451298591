<template>
  <div
    class="calendar-comp-item"
    @click="$emit('click-handler', data)"
  >
    <r-block
      space-between
      no-padding
      col
      class="calendar-comp-item__title"
    >
      <r-text>
        {{ getData(data.day) }}
      </r-text>
      <r-text
        v-if="getDescription(data.day).length"
        type="caption"
      >
        {{ getDescription(data.day).length }} увед.
      </r-text>
    </r-block>
    <cell-list
      v-if="getDescription(data.day).length"
      :list="getDescription(data.day)"
    />
  </div>
</template>

<script>
export default {
  components: {
    cellList: () => import('./cell-list')
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    list: {
      type: Array,
      required: true
    }
  },
  methods: {
    getData(day) {
      return this.$ritmDate.toFormat(day, 'DD')
    },
    getDescription(day) {
      const query = this.$ritmDate.toFormat(day, 'DD-MM-YYYY')
      const items = this.list?.filter(
        m => this.$ritmDate.toFormat(m.event_time, 'DD-MM-YYYY') === query
      )

      return items || []
    }
  }
}
</script>

<style lang="scss">
.calendar-comp-item {
  display: grid;
  grid-gap: 0.25rem;
  padding: 0.5rem;
  align-content: flex-start;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
